<template>
  <div style="width: 1000px;min-height:100vh;display: flex;justify-content: center;align-items: center;margin: 0 auto;">
    <div style="margin-top: 120px;">
      <div style="width: 1000px;height:800px;background: #FFFFFF">
        <div style="clear: both;display: flex;align-items: center;padding-top: 60px;">
          <div style="font-size: 25px;color:#444444;font-weight:bold;display: flex;justify-content: center;flex: 1;">派车单</div>
        </div>
        <div style="clear: both;padding-top: 30px;">
          <div style="width: 920px;height: 10px;background: #FF9800;border-radius: 20px 20px 0px 0px;margin: 0 auto;"></div>
          <div class="gradient-border" style="width: 916px;min-height: 450px;clear: both;margin: 0 auto;box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.16);">
            <teble style="width: 100%;font-size: 14px;">
              <tr class="typeface">
                <th style=" width: 100px;height: 20px;padding: 23px 42.5px;font-size: 17px; color: #444444;border-left:1px solid #808080;border-right:1px solid #CFCFCF;font-weight: bold;border-bottom:1px solid #CFCFCF;border-left: none">申请部门</th>
                <td style=" width: 260px;font-size: 17px;height: 20px;color: #444444;padding: 10px;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #CFCFCF;">{{content.fd_1704433267603}}</td>
                <th style=" width: 100px;height: 20px;padding: 23px 41px;font-size: 17px; color: #444444;font-weight: bold;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #CFCFCF;">部门负责人</th>
                <td style=" width: 260px;font-size: 17px;height: 20px;color: #444444;padding: 10px;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #808080;border-right: none">{{content.fd_1704433268932}}</td>
              </tr>
            </teble>
            <table border="1" style="width: 100%;height: 200px;overflow: hidden;font-size: 14px;border: none">
              <tr class="typeface">
                <th class="title">项目名称</th>
                <td class="content">{{content.fd_1677729356192}}</td>
              </tr>
              <tr class="typeface" style="border-bottom: none">
                <th class="title">同车人员</th>
                <td class="content">{{content.fd_1704433290302}}</td>
              </tr>
              <tr class="typeface" style="border-bottom: none">
                <th class="title">用车时间</th>
                <td class="content"><div style="float: left;" v-for="(item,index) in content.fd_1704433340752" :key="index" :style="index == 1?'margin-left: 10px':''">{{item}} <span style="padding: 0 10px;" v-if="index !== content.fd_1704433340752.length - 1">至</span></div></td>
              </tr>
            </table>
            <teble style="width: 100%;font-size: 14px;">
              <tr class="typeface">
                <th style=" width: 100px;height: 20px;padding: 23px 42.5px;font-size: 17px; color: #444444;border-left:1px solid #808080;border-right:1px solid #CFCFCF;font-weight: bold;border-bottom:1px solid #CFCFCF;border-left: none">驾驶员</th>
                <td style=" width: 260px;font-size: 17px;height: 20px;color: #444444;padding: 10px;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #CFCFCF;">{{content.fd_1704433394283}}</td>
                <th style=" width: 100px;height: 20px;padding: 23px 41px;font-size: 17px; color: #444444;font-weight: bold;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #CFCFCF;">车型车号</th>
                <td style=" width: 260px;font-size: 17px;height: 20px;color: #444444;padding: 10px;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #808080;border-right: none">{{content.fd_1704433396119}}</td>
              </tr>
            </teble>
              <table border="1" style="width: 100%;height: 200px;overflow: hidden;font-size: 14px;border: none">
                  <tr class="typeface">
                      <th class="title" style="width: 200px">行驶里程</th>
                      <td class="content">{{content.fd_1704441967526}}</td>
                  </tr>
                  <tr class="typeface">
                      <th class="title">行驶路线</th>
                      <td class="content">{{content.fd_1704433546908}}</td>
                  </tr>
                  <tr class="typeface">
                      <th class="title" style="border-bottom: none">行政部（签字）</th>
                      <td class="content" style="border-bottom: none">{{content.fd_1704433571303}}</td>
                  </tr>
              </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },

  computed: {

  },
  data () {
    return {
      content: {}
    }
  },
  mounted:function(){
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    this.getRoomContent()
  },

  methods:{
    // 获取内容
    getRoomContent() {
      this.newApi.getRoomContentInfo({id: this.$route.params.id}).then(res =>{
        this.content = res.data.content;
      })
    },

  }
}
</script>
<style scoped>
.title {
  width: 100px;
  height: 20px;
  padding: 23px 26px;
  font-size: 17px;
  color: #444444;
  border-bottom:1px solid #CFCFCF;
  font-weight: bold;
  border-right:1px solid #CFCFCF;
  border-left: none;
  border-top: none;
  font-family:'SimHei';

}
.content {
  font-size: 17px;
  height: 20px;
  color: #444444;
  width: 80%;
  border-bottom:1px solid #CFCFCF;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 10px;
}

.gradient-border {
  border-right: 2px solid #ddd;
  border-left: 2px solid #ddd;
  border-image: linear-gradient(rgb(255, 152, 0), #FFFFFF) 30 30;
}

/deep/ .el-radio__inner {
  width: 17px;
  height: 17px;
}
/deep/ .el-radio__inner::after {
  width: 7px;
  height: 7px;
  left: 51%;
  top: 54%;
}
/deep/ .el-radio__label {
  font-size: 15px;
}
</style>